<template>
  <f-container topBottom>
    <v-card elevation="0">
      <v-card-text>
        Você vai gerar um certificado customizado para os seguites usuários [
        {{ selectedUsers.length }} ]:
        <br />
        <br />
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  Master
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in selectedUsers" :key="item._id">
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.master }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <lms-btn-holder>
      <v-btn large depressed color="primary" @click="next">GERAR</v-btn>
      <v-btn large depressed @click="back">VOLTAR</v-btn>
    </lms-btn-holder>
  </f-container>
</template>

<script>
export default {
  computed: {
    selectedUsers() {
      return this.$store.state.certificateGenerator.selected;
    },
  },
  methods: {
    next() {
      this.$store.dispatch('certificateGenerator/generate');
    },
    back() {
      this.$store.dispatch('certificateGenerator/pageSelect');
    },
  },
};
</script>
