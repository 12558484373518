<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-selection-section v-if="pageControl == 'DATA_SELECT'" />
    <f-review-section v-if="pageControl == 'REVIEW'" />
    <f-confirmation-section v-if="pageControl == 'CONFIRMATION'" />
  </div>
</template>

<script>
import FSelectionSection from '../../components/views/certificateGenerator/SelectionSection/Section.vue';
import FReviewSection from '../../components/views/certificateGenerator/ReviewSection.vue';
import FConfirmationSection from '../../components/views/certificateGenerator/ConfirmationSection.vue';

export default {
  components: {
    FSelectionSection,
    FReviewSection,
    FConfirmationSection,
  },
  computed: {
    pageControl() {
      return this.$store.getters['certificateGenerator/pageControl'];
    },
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.certificateGenerator');
    },
  },
  watch: {
    pageControl() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.$store.dispatch('user/getAllClean');
    },
  },
  destroyed() {
    this.$store.dispatch('certificateGenerator/clear');
  },
};
</script>
