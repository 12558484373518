<template>
  <div>
    CERTIFICATE
    <f-group-container>
      <v-text-field
        v-model="certificate.name"
        :label="$t('labels.name')"
        outlined
        clearable
      />
      <v-select
        v-model="certificate.model"
        :items="certificateLayout"
        item-value="model"
        item-text="title"
        name="certificate_layout"
        :label="$tt.capitalize($t('labels.certificateLayout'))"
        outlined
      />
      <f-lang-selector :langs="langs" />
      <v-tabs-items v-model="selectedLang">
        <v-tab-item v-for="lang in langs" :key="lang">
          <div class="input-spacer">
            <v-text-field
              v-model="certificate.data.title[lang]"
              outlined
              :label="
                $tt.capitalize($t('labels.title')) + $tt.uppercase(` (${lang})`)
              "
              :counter="maxTitleSize"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <f-custom-date />
    </f-group-container>
  </div>
</template>

<script>
import FGroupContainer from '../../../shared/GroupContainer.vue';
import FCustomDate from './CustomDate.vue';

export default {
  components: {
    FGroupContainer,
    FCustomDate,
  },
  data: () => ({
    selected: 'default',
    tab: 0,
    maxTitleSize: 90,
  }),
  computed: {
    langs() {
      return this.$store.state.system.langs;
    },
    selectedLang() {
      return this.$store.state.system.selectedLang;
    },
    certificate() {
      let cert = this.$store.state.certificateGenerator.certificateData;
      if (!cert.data) {
        cert.data = {
          title: {},
        };
      }
      return this.$store.state.certificateGenerator.certificateData;
    },
    certificateLayout() {
      return [
        {model:'custom', title: "Modelo Customizado"},
        {model:'champion', title: "Champion Nível 1"},
        // {model:'champion_2', title: "Champion Nível 2"},
        {model:'materials', title: "Materiales"},
        {model:'training_programa_level_1', title: "Training Program Level 1"},
        {model:'training_programa_level_2', title: "Training Program Level 2"},
        {model: 'onboarding_chile', title: "Onboarding Fluke - Chile" },
      ]
    }
  },
};
</script>
