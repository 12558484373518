<template>
  <div>
    USERS
    <f-group-container>
      <v-data-table
        class="hide-check"
        v-model="selected"
        :headers="headers"
        :items="users"
        item-key="_id"
        show-select
        :single-select="false"
        :some-items="true"
        :search="search"
        dense
        fixed-header
        height="400"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                :label="$t('labels.search')"
                class="mx-4"
                outlined
                clearable
              />
            </v-col>
            <v-col cols="6"
              ><div
                class="selected-users-table"
                :class="{ active: selected.length }"
              >
                <div>
                  Selected users: <span>{{ selected.length }}</span>
                </div>
                <div class="close-btn" @click="clearItems">
                  <v-icon>mdi-close</v-icon>
                </div>
              </div></v-col
            >
          </v-row>
        </template></v-data-table
      >
    </f-group-container>
  </div>
</template>

<script>
import FGroupContainer from '../../../shared/GroupContainer.vue';

export default {
  components: {
    FGroupContainer,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    users() {
      return this.$store.state.user.list;
    },
    selected: {
      get() {
        return this.$store.state.certificateGenerator.selected;
      },
      set(value) {
        this.$store.state.certificateGenerator.selected = value;
      },
    },
    headers() {
      return [
        {
          text: 'Nome',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email',
        },
        {
          text: 'Master',
          align: 'start',
          value: 'master',
        },
      ];
    },
  },
  methods: {
    clearItems() {
      this.selected = [];
    },
  },
};
</script>

<style lang="scss">
@import '../../../../assets/scss/base/variables';

.hide-check {
  .v-data-table-header {
    .v-simple-checkbox {
      display: none;
    }
  }
  .v-data-table__wrapper {
    margin: 0 16px;
  }
}
.selected-users-table {
  background-color: $borders-separators-color;
  border-radius: 3px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;

  &.active {
    background-color: #fdc131;
    div,
    span,
    i {
      color: #000;
    }
  }

  span {
    font-weight: bold;
    padding-left: 10px;
    font-size: 1.1rem;
  }

  .close-btn {
    cursor: pointer;
    padding: 20px;
  }
}
</style>
