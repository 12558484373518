<template>
  <f-container topBottom>
    <v-card elevation="0">
      <v-card-text>
        <f-layout-select />
        <br />
        <f-user-select />
      </v-card-text>
    </v-card>
    <lms-btn-holder>
      <v-btn large depressed @click="next" color="primary">REVIEW</v-btn>
      <v-btn large depressed @click="back">{{ $t('labels.cancel') }}</v-btn>
    </lms-btn-holder>
  </f-container>
</template>

<script>
import FLayoutSelect from './LayoutSelect.vue';
import FUserSelect from './UserSelect.vue';

export default {
  components: {
    FLayoutSelect,
    FUserSelect,
  },
  methods: {
    next() {
      this.$store.dispatch('certificateGenerator/pageReview');
    },
    back() {
      this.$router.push({ name: 'settings' });
    },
  },
};
</script>
