<template>
  <div>
    <f-container topBottom>
      <v-card elevation="0">
        <v-card-text>
          Certificado gerado com sucesso!
        </v-card-text>
      </v-card>

      <lms-btn-holder>
        <v-btn large depressed @click="begin">Gerar outro</v-btn>
        <v-btn large depressed @click="back">settings</v-btn>
      </lms-btn-holder>
    </f-container>
  </div>
</template>

<script>
export default {
  methods: {
    begin() {
      this.$store.dispatch('certificateGenerator/pageSelect');
    },
    back() {
      this.$store.dispatch('certificateGenerator/pageSelect');
    },
  },
};
</script>
