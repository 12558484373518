import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCheckbox,{staticStyle:{"margin":"0"},attrs:{"label":_vm.$t('labels.customDate'),"hide-details":""},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}}),_c('br'),_c('f-expandable-div',{attrs:{"expanded":_vm.opened}},[_c('div',{staticStyle:{"min-height":"55px"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.certificate.data.date,"offset-y":""},on:{"update:returnValue":function($event){return _vm.$set(_vm.certificate.data, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.certificate.data, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.certificate.data.date),callback:function ($$v) {_vm.$set(_vm.certificate.data, "date", $$v)},expression:"certificate.data.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","dark":true},model:{value:(_vm.certificate.data.date),callback:function ($$v) {_vm.$set(_vm.certificate.data, "date", $$v)},expression:"certificate.data.date"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.certificate.data.date)}}},[_vm._v(" OK ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }