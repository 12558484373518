<template>
  <div>
    <v-checkbox
      v-model="opened"
      :label="$t('labels.customDate')"
      hide-details
      style="margin: 0"
    />
    <br />
    <f-expandable-div :expanded="opened">
      <div style="min-height: 55px;">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="certificate.data.date"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="certificate.data.date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="certificate.data.date"
            no-title
            scrollable
            :dark="true"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(certificate.data.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </f-expandable-div>
  </div>
</template>

<script>
import FExpandableDiv from "../../../shared/ExpandableDiv.vue";

export default {
  components: {
    FExpandableDiv
  },
  data: () => ({
    opened: false,
    menu: false
  }),
  computed: {
    certificate() {
      return this.$store.state.certificateGenerator.certificateData;
    }
  },
  watch: {
    opened: function(val) {
      if(!val) this.certificate.data.date = null
    }
  }
};
</script>
