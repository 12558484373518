import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" USERS "),_c('f-group-container',[_c(VDataTable,{staticClass:"hide-check",attrs:{"headers":_vm.headers,"items":_vm.users,"item-key":"_id","show-select":"","single-select":false,"some-items":true,"search":_vm.search,"dense":"","fixed-header":"","height":"400"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('labels.search'),"outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"selected-users-table",class:{ active: _vm.selected.length }},[_c('div',[_vm._v(" Selected users: "),_c('span',[_vm._v(_vm._s(_vm.selected.length))])]),_c('div',{staticClass:"close-btn",on:{"click":_vm.clearItems}},[_c(VIcon,[_vm._v("mdi-close")])],1)])])],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }